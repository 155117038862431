body {
  font-size: $body-font-size;
  font-family: $font;
  line-height: 1.3;
}



.main-container{
  display: flex;
  flex-direction: row;
  @include respond-down(small){
    flex-direction: column;
  }


  .aside-bar-left{
    flex: 1 1 20%;
    max-width: 20%;
    margin-right: 20px;
    @extend .small-behavior-aside;
    @include respond-down(small){
      order: 2;
    }
  }


  .aside-bar-right{
    flex: 1 1 20%;
    max-width: 20%;
    text-align: right;
    margin-left: 20px;
    @extend .small-behavior-aside;
    @include respond-down(small){
      order: 3;
    }

    .banner-aside{
      display: block;
      margin-bottom: 20px;
      text-align: right;

      @include respond-down(small){
        display: inline-block;
      }
      img{
        font-size: 0px;
        display: inline-block;
      }
    }
  }

  .small-behavior-aside{
    @include respond-down(small){
      display: block;
      width: 100%;
      flex: 1 1 100%;
      margin: 0;
      max-width: 100%;
      white-space: nowrap;
      overflow: auto;
    }
  }



  .center{
    flex: 1 1 60%;
    @include respond-down(small){
      order: 1;
    }

  }
}

a{
  text-decoration: none;
  background: transparent;
  color: black;
}



.breadcrumbs-list{
  display: flex;
  margin: 20px 0px;
  font-size: 12px;
  a{
    font-size: 12px;
    border-bottom: 1px solid;
    &:hover{
      border-bottom-color: transparent;
    }
  }
  .delimiter{
    margin: 0 4px;
  }
}

h1{
  margin-bottom: 30px;
}


.social-icon{
  width: 49px;
  height: 49px;
  background: $primary-color;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.styled-header{
  background: $primary-color;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  padding: 8px 0px 7px 0px;
  margin-bottom: 23px;

  &.smalled{
    background: #4d8dda;
    color: #ffffff;
    font-size: 25px;
  }
}

.d-flex{
  display: flex;
}

.j-center{
  justify-content: center;
}


.big-list-links{
  .list-item{
    margin-bottom: 20px;
    a{
      display: flex;
      &:hover{
        .name{
          background: #c1d2e8;
          color: #162946;
        }
        .icon{
          background: #8491a5;
        }
      }
      .name{
        color: #ffffff;
        background: #8491a5;
        flex: 1 1 auto;
        padding: 10px 10px;
        font-weight: bold;
        transition: $default-transition;
        b{
          border-bottom: 1px solid;
        }
      }
      .icon{
        padding: 10px 0px;
        width: 50px;
        background: #c1d2e8;
        flex: 0 0 50px;
        text-align: center;
        transition: $default-transition;
      }
    }
  }
}

.tiles-list-wrapper{
  .tiles-list{
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    li{
      width: 33.3333%;
      padding: 10px;
      flex: 0 0 auto;
      @media (max-width: 900px) {
        width: 50% !important;
      }
      @media (max-width: 600px) {
        width: 100% !important;
      }
      a{
        display: flex;
        justify-content: center;
        background: #8bbdf93b;
        border: 5px solid #8bbdf9;
        flex-wrap: wrap;
        .name{
          background: $grey-color;
          padding: 8px 10px;
          font-size: 16px;
          text-align: center;
          width: 100%;
          height: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1.2;
        }
        img{
          display: flex;
          height: 250px;
          object-fit: cover;
          object-position: 50% 20%;
          width: 100%;
        }
      }
    }
  }
}
.play-parent{
  position: relative;
  display: block;
  .play-holder{
    position: absolute;
    background: rgba(46, 68, 90, 0.36);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg-icon{
      background: #ffffff8a;
      border-radius: 100px;
      border: 1px solid #fff;
    }
  }
}
.heading-container{
  .styled-header{
    margin-bottom: 0px;
  }
}

table{
  width: 100% !important;
}

table.clear{
  width: auto !important;
  tr:first-child td{
    background: transparent;
    font-weight: normal;
  }
  td{
    border: none;
    padding: 0px;
  }
}

