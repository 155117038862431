.menu-container{
  border: 1px solid #C6C6C6;
  @include respond(mobile){
    padding: 10px 0px;
    position: relative;
  }
  .mobile-menu-opener{
    display: flex;
    justify-content: flex-end;
    a{
      color: black;
      text-transform: uppercase;
      font-size: 13px;
    }
    @media (min-width: 1024px) {
      display: none !important;
    }
  }
  .main-menu{
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9999;


    @include respond(mobile){
      position: absolute;
      background: $secondary-color;
      left: 0;
      right: 0;
      top: 40px;
      padding-left: 10px;
      display: none;
      &.open{
        display: block;
      }
    }

    .root-menu{
      position: relative;

      &.red-color{
        a{
          color: $primary-color;
        }
      }
      &:first-child{
        a{
          position: relative;
          .name{
            padding-left: 0px;
          }
          &:before{
            width: 18px;
            top: 0;
            bottom: 0;
            background: inherit;
            content: '';
            position: absolute;
            left: 0;
            transform: translateX(-100%);
            pointer-events: none;
          }
        }
        ul{
          left: -18px;
        }
      }
      &:last-child{
        a{
          position: relative;
          .name{
            padding-right: 0px;
            &:after{
              display: none;
            }
          }
          &:before{
            width: 18px;
            top: 0;
            bottom: 0;
            content: '';
            background: inherit;
            position: absolute;
            right: 0;
            transform: translateX(100%);
            pointer-events: none;
          }
        }
      }
      &:hover{
        >ul{
          display: block;
          @include respond(mobile){
            display: none;
          }
        }
        a{
          background: $primary-color;
          color: #ffffff;
          .dropper{
            svg{
              transform: rotate(-180deg);
            }
          }
        }
      }

      &.mobile-active{
        >ul{
          display: block;
        }
      }

      a{
        color: black;
        padding: 15px 0px;
        display: inline-block;
        font-size: 14px;
        position: relative;
        text-transform: uppercase;
        font-weight: bold;
        transition: $default-transition;
        .name{
          padding: 0px 18px;
          &:after{
            content: '';
            background: $primary-color;
            position: absolute;
            width: 2px;
            height: 10px;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 25px;
          }
        }
        .dropper{
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
          svg{
            transition: $default-transition;
            width: 7px;
            height: 7px;
          }
        }
      }

      .child-menu{
        position: absolute;
        top: calc(100% + 1px);
        display: none;
        min-width: 180px;
        left: 0;
        right: 0;
        border: 1px solid #dee1e8;
        border-top: transparent;
        background-color: rgb(245, 249, 255);
        box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.07);
        @include respond(mobile){
          position: static;
          box-shadow: none;
          width: auto;
          background: $secondary-color;
          border-left: none;
          border-right: none;
          padding-left: 5px;
        }
        li{

          a{
            color: #565f68;
            padding: 12px 10px 12px 10px;
            border-bottom: 1px solid #dee1e8;
            display: block;
            font-size: 12px;
            background: #ffffff;
            &:after, &:before{
              display: none;
            }
            &:hover{
              background: $primary-color;
              color: #ffffff;
            }
          }
          ul{
            left: 100%;
            top: 0;
            box-shadow: none;
            border-top: none;
          }
        }
      }

    }


  }
}


header {
  @media (min-width: 640px) and (max-width: 977px) {
    .medium-2 {
        width: 100%;
    }
  }
}