.pages-list{
  .page-section{
    display: flex;
    padding: 30px 0px;
    border-bottom: 1px solid rgba(86, 95, 104, 0.24);
    @include respond(small){
      display: block;
    }

    &:first-child{
      padding-top: 0px;
    }

    .left-image{
      width: 200px;
      margin-right: 15px;
      flex: 0 0 200px;
      height: 150px;
      display: flex;
      img{
        object-fit: cover;
      }
    }
    .right-data{
      flex: 1 1 auto;

      .date{
        font-size: 12px;
        color: #9f9f9f;
        display: flex;
        margin-bottom: 20px;
        .icon{
         margin-right: 10px;
        }
      }

      .description{
        color: #565f68;
        font-size: 14px;
      }
    }


  }
  .all-list{
    max-width: 200px;
    margin: 20px auto 0px auto;
  }
}
