$default-input-height: 45px;

.button, button {
  margin: 0;
  text-align: center;
  transition: $default-transition;
  display: inline-block;
  background: #ffffff;
  font-size: 14px;
  cursor: pointer;
  color: $primary-color;
  text-decoration: none;
  border: none;
  padding: 0 10px;
  font-weight: bold;

  &:hover{
    color: black;
  }

  &.expand, &.wide {
    width: 100%;
  }


  &.height {
    height: $default-input-height;
    line-height: $default-input-height;
  }

  &.red{
    background: $primary-color;
    color: #ffffff;
    border: 2px solid $primary-color;

    &:hover{
      background: transparent;
      color: $primary-color;
    }
  }

  &.default{
    padding: 12px 35px;
    font-weight: 300;
  }
}

form {
  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: $alert-color;
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';
  #{$inputs}, textarea, select {
    border: 1px solid rgba(193, 193, 193, 0.3);
    box-shadow: none;
    margin: 0;
    padding: 0 10px;
    font-size: 14px;
    width: 100%;
    display: block;
  }

  #{$inputs}, select, textarea {
    height: $default-input-height;
  }

  textarea {
    padding: 5px 10px;
  }

}
