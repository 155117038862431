@include sticky-footer(600px);

#footer{
  background: $primary-color;
  color: #fff;
  height: unset !important;

  .footer-link {
    color: white;
  }

  .footer-menu{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 25px 0px 10px 0px;
    border-bottom: 1px solid #DF7B7B;

    @media (max-width: 768px) {
      flex-direction: column;
    }
    li{
      a{
        color: #fff;
        margin-bottom: 3px;
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 1px solid transparent;
        transition: $default-transition;
        position: relative;
        &:after{
          position: absolute;
          content: '';
          right: -25px;
          height: 10px;
          width: 2px;
          background: #DF7B7B;
          top: 50%;
          transform: translateY(-50%);
        }
        &:hover{
          border-bottom-color: #ffffff;
        }
      }

      &:last-child{
        a{
          &:after{
            display: none;
          }
        }
      }
    }
  }


  .footer-contacts{
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .column-wrapper{
    padding: 20px 0px;
    .label{
      font-size: 20px;
      margin-bottom: 20px;
    }



    ul{
      li{
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 10px;
        .icon{
          margin-right: 10px;
          width: 30px;
          text-align: center;
        }
      }
    }
  }

  .form-wrapper{
    .success-text{
      display: none;
    }
    .line{
      display: flex;
      justify-content: space-between;
      margin: 0 -10px;
      align-items: flex-end;
      .button{
        width: 100%
      }
      .form-field{
        flex: 1 1 auto;
        padding: 0 10px;
        width: 50%;
      }

      label{
        font-size: 12px;
        color: #fff;
        margin-bottom: 10px;
      }
      .errors{
        font-size: 11px;
        color: black;
        position: absolute;
        margin-top: -18px;
        margin-left: 10px;
        transition: $default-transition;
      }
      input:focus+.errors{
        opacity: 0;
      }

      input:not(:placeholder-shown)+.errors {
        opacity: 0;
      }

      textarea:not(:placeholder-shown)+.errors{
        opacity: 0;
      }

      textarea:focus+.errors{
        opacity: 0;
      }

      input, textarea{
        &::placeholder {
          color: black;
          font-weight: bold;
          font-size: 12px;
          transition: all 0.5s;
        }
      }
      input:focus{
        &::placeholder {
          opacity: 0;
          transform: translateY(-10px);
        }
      }
      textarea:focus{
        &::placeholder {
          opacity: 0;
          transform: translateY(-10px);
        }
      }
    }
  }

  .main-image-holder{
    width: 520px;
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
  hr{
    padding: 0px;
    margin: 0px;
    transform: translateY(-5px);
  }
  .copy{
    text-align: center;
    padding: 20px 0px;
  }
}
