.events-list-holder{
  display: flex;
  margin: 0 -12px;
  flex-wrap: wrap;
  .event-wrapper{
    width: 33.3333%;
    padding: 12px;
    @media (max-width: 900px) {
      width: 50%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }

    a{
      &:hover{
        .logo-holder{
          background: #8bbef9;
        }
      }
    }


    .logo-holder{
      background: #E1E1E1;
      height: 230px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $default-transition;
      .logo{
        width: 100%;
        height: 100%;

        img{
          display: flex;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 0%;
        }
      }
    }

    .info-holder{
      border: 1px solid #C1D2E8;
      .name{
        color: black;
        font-weight: bold;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 8px;
        text-align: center;
        border-bottom: 1px solid #C1D2E8;
        display: -webkit-box;

      }

      .params{
        font-size: 11px;
        font-weight: bold;
        li{
          display: flex;
          border-bottom: 1px solid #C1D2E8;
          padding: 12px 10px;
          align-items: center;
          &:last-child{
            border-bottom: transparent;
          }
          .icon-holder{
            margin-right: 10px;
          }
        }
      }
    }
  }
}

