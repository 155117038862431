$max-row-width: 1200;
$grid-columns: 12;
$grid: (
        small: (
                from: 0,
                to: 639,
                gutter: 12,
                spacer: 8
        ),
        medium: (
                from: 640,
                to: 977,
                gutter: 30
        ),
        mobile: (
                from: 0,
                to: 1024,
                gutter: 30
        ),
        middle: (
                from: 978,
                to: 1199,
                gutter: 30
        ),
        large: (
                from: 1200,
                gutter: 30
        )
);
$body-font-size: 16px;
$font: "Clear sans", sans-serif;
$h-font: "Clear sans", sans-serif;

$t: transparent;

$primary-color: #C2272D;
$secondary-color: #8BBDF9;
$alert-color: #b50000;
$grey-color: #E1E1E1;


$default-transition: all 0.4s;
