article {
  &, p, li {
    font-size: 16px;
    color: #090a0c;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.last-content {
    margin-bottom: 30px;

    @media (max-width: 768px) {
      table {
        tr {
          display: flex;
          flex-direction: column;
          height: unset !important;
        }
        td {
          height: unset !important;
        }
      }
    }
    img {
      height: unset !important;
    }
  }

  ol{
    margin: 15px 10px;
    counter-reset: myCounter;

    li {
      margin-bottom: 5px;
      position: relative;
      font-size: 15px;
    }

    li:before {
      counter-increment: myCounter;
      content:counter(myCounter);
      color: white;
      background: $primary-color;
      display: inline-block;
      text-align: center;
      margin-right: 10px;
      line-height: 20px;
      width: 20px;
      height: 20px;
      font-size: 12px;
      border-radius: 50%;
    }

  }
  ul {
    margin: 15px 10px;


    li {
      margin-bottom: 5px;
      position: relative;
      padding-left: 15px;
      font-size: 16px;

      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        content: '';
        width: 5px;
        height: 5px;
        background: #c1272d;
        transform: translateY(-50%);
        border-radius: 10px;
      }
    }
  }

  table {
    width: 100%;

    tr:first-child {
      td {
        font-weight: bold;
        background: #1b94e021;
      }
    }

    td {
      border: 1px solid #0251ac42;
      padding: 10px 5px;
    }
  }

  blockquote {
    display: flex;
    position: relative;
    padding-left: 80px;
    margin: 30px 20px;

    p{
      color: #565f68;
      font-size: 20px;
    }
    &:before{
      content: ',,';
      position: absolute;
      left: 0px;
      font-size: 130px;
      top: -80px;
      height: 30px;
      line-height: 1;

    }
  }
}
