.employers-list{
  display: flex;
  flex-wrap: wrap;
  .employer{
    width: 33.3333%;
    text-align: center;
    margin-bottom: 50px;
    @media (max-width: 990px) {
      width: 50% !important;
    }
    @media (max-width: 660px) {
      width: 100% !important;
    }
    .wrapper{
      display: inline-block;
      width: 300px;
    }
    .name{
      font-size: 18px;
      background: #c2272d;
      color: #fff;
      text-align: center;
      padding: 5px 10px;
      font-weight: bold;
    }
    .photo{

      height: 300px;
      img{
        width: 100%;
        height: 100%;
        display: flex;
        object-fit: cover;
        object-position: 50% 20%;
      }
    }

    .contacts{
      text-align: left;
      border: 1px solid #c3272d;
      li{
        display: flex;
        font-size: 14px;
        border-bottom: 1px solid #c3272d;
        padding: 8px 10px;
        align-items: center;
        &:last-child{
          border: none;
        }
        .value{
          margin-left: 10px;
          line-height: 1.1;
        }
      }
    }
  }
}
