.slider-block {
  position: relative;
  z-index: 1;
  @include respond(small){
    margin-bottom: 60px;
  }

  .slide {
    position: relative;
    @include respond(small){
      height: 250px;
      img{
        display: flex;
        height: 100%;
      }
    }

    .description-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 5%;
      color: #fff;

      .name {
        font-size: 40px;
        margin-bottom: 40px;
        @include respond(small){
          font-size: 20px;
        }
      }

      .description {
        font-size: 18px;
        line-height: 1.5;
        @include respond(small){
          font-size: 12px;
        }
      }

      .buttons {
        margin-top: 40px;

        .button {
          width: 200px;
          height: 50px;
          padding: 0px;
          line-height: 50px;
          &:hover{
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);

    @include respond(small){
      bottom: -40px;
    }

    li {
      margin: 0 10px;

      button {
        padding: 0px;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background: transparent;
        border: 1px solid $secondary-color;
        color: $secondary-color;

        @include respond(small){
          font-size: 10px;
          width: 15px;
          height: 15px;
          color: transparent;
        }

      }

      &.slick-active {
        button {
          background: $secondary-color;
          color: #fff;
          @include respond(small){
            color: transparent;
          }
        }
      }
    }
  }
}





