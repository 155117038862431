
.banners-holder{
  padding: 23px 43px;
  background: #E1E1E1;

  .banner{
    padding: 0px 7px;
  }
}

.slick-arrow{
  font-size: 0px;
  width: 17px;
  height: 33px;
  position: absolute;
  background: transparent;
  top: 50%;
  transform: translateY(-50%) translateX(120%);
  z-index: 99;

  &.slick-prev{
    left: 0px;
    background: transparent url("../svg/arrow-slider-left.svg") 50% 50% no-repeat;
    transform: translateY(-50%) translateX(-120%);
  }

  &.slick-next{
    right: 0px;
    background: transparent url("../svg/arrow-slider-right.svg") 50% 50% no-repeat;
  }
}
