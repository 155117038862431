.block-container{
  margin-bottom: 20px;
  h2{
    margin-bottom: 30px;
  }
  @import "_slider";
  @import "_pages-list";
  @import "_map";
}

@import "_event.scss";
@import "_partners.scss";
@import "_employers.scss";


.base-block{
  margin-top: 45px;
  &:last-child{
    margin-bottom: 45px;
  }
}


.all-link{
  margin-top: 20px;
}
.content-doted-slider{
  @media (max-width: 768px) {
    background: #8bbdf9 !important;
  }
  .slide{
    font-size: 0px;
  }
  .slide-wrapper{
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    .right-photo{
      flex: 0 0 60%;
      max-height: 450px;
      overflow: hidden;
      img{
        width: 100%;
        display: flex;
        object-fit: cover;
        object-position: 50% 50%;
        height: 100%;
      }
    }
    .left-data{
      margin-left: 10px;
      background: #8BBDF9;
      padding: 20px;
      color: #ffffff;
      flex: 1 1 auto;
      @media (max-width: 768px) {
        margin-left: 0 !important;
      }
      .top-line{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 14px;
        .logo{
          display: block;
          width: 60px;
          height: 60px;
          background: #ffffff;
          img{
            width: 100%;
            height: 100%;
            display: flex;
            object-fit: contain;
          }
        }

        .additional{
          background: #ffffff;
          color: #8BBDF9;
          text-transform: uppercase;
          font-weight: bold;
          padding: 5px 8px;
        }
      }
      .description{
        font-weight: bold;
        max-width: 500px;
        padding: 20px 0px;
        font-size: 24px;
        margin-top: 30px;
      }
    }
  }
  .slick-dots{
    position: absolute;
    right: 30%;
    display: flex;
    bottom: 20px;
    button{
      background: transparent;
      font-size: 0px;
      color: transparent;
      width: 14px;
      height: 14px;
      border-radius: 10px;
      border: 1px solid #ffffff;
      padding: 0px;
    }
    li{
      margin: 0 3px;
    }
    li.slick-active{
      button{
        background: #ffffff;
      }
    }
  }
  .more-holder{
    max-width: 200px;
    margin-top: 20px;
  }
}


footer .column.large-12 {
  display: flex;
  align-items: center;
  column-gap: 30px;
  row-gap: 10px;
  flex-wrap: wrap;
  padding: 10px;
}