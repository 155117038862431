.top-header {
  padding: 25px 0px 20px 0px;
  .logo-description-holder {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .logo-link {
      display: flex;

      .logo-image{
        width: 60px;
      }

      .text-holder{
        margin-left: 20px;

        .subtitle{
          font-size: 13px;
          line-height: 16px;
          display: block;
        }

        .title{
          display: block;
          font-size: 32px;
          font-weight: bold;
          line-height: 24px;
          margin-top: 10px;
        }
      }
    }

    .description{
      color: $primary-color;
      font-size: 13px;
      line-height: 18px;
      padding-right: 26px;
      padding-left: 20px;
      transform: translateY(-5px);
      @media (max-width: 768px) {
        padding-right: 0;
        padding-left: 0;
        padding-top: 20px;
      }
      .line{
        max-width: 245px;
        &:last-child{
          margin-top: 10px;
        }
      }
    }
  }

  .icons-holder{
    display: flex;
    justify-content: flex-end;

    .social-icon{
      margin-left: 15px;
    }
  }
}
