.pagination-block{
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  .pagination-list{
    display: flex;
    margin: 0 auto;

    li{
      margin: 0 5px;
      a{
        border-bottom: 1px solid transparent;
        &:hover{
          border-bottom-color: black;
        }
      }
    }
  }
}
